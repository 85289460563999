import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import parse from 'html-react-parser';
import {
  Box,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Textarea,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import Button from 'Library/Button';

import {
  useCreateCandidateNoteMutation,
  useDelCandidateNoteMutation,
  useListCandidateNoteMutation,
  useUpdateCandidateNoteMutation,
} from 'store/candidatenote.slice';

import nl2br from 'utils/nl2br';
import FAIcon from 'components/lib/FAIcon';
import User from 'assets/images/user.png';
import CancelModal from '../Drawer/Interviews/modal/Cancel';
import delay from 'utils/delay';

interface NotesModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function NotesModal({ isOpen, onClose }: NotesModalProps) {
  const { candidateData } = useSelector((state: any) => state.candidates);
  const { candidateNotes, candidateNotePgBtn } = useSelector(
    (state: any) => state.candidateNotes
  );

  const [reqCreate, resCreate] = useCreateCandidateNoteMutation();
  const [reqList] = useListCandidateNoteMutation();
  const [reqDel, resDel] = useDelCandidateNoteMutation();
  const [reqUpdate, resUpdate] = useUpdateCandidateNoteMutation();

  const [noteContent, setNoteContent] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [noteId, setNoteId] = useState<number | null>(null);
  const [hoverNoteId, setHoverNoteId] = useState<number | null>(null);
  const [showInput, setShowInput] = useState(false);

  const candidateID = candidateData?.id || null;

  const {
    isOpen: isOpenCancelModal,
    onOpen: onOpenCancelModal,
    onClose: onCloseCancelModal,
  } = useDisclosure();

  useEffect(() => {
    const fetchNotes = async () => {
      await delay(500);
      await reqList({ data: candidateNotePgBtn, id: candidateID });
      onCloseCancelModal();
    };
    if (candidateID) {
      fetchNotes();
    }
  }, [candidateID, resDel.isSuccess]);

  useEffect(() => {
    if (resCreate.isSuccess || resUpdate.isSuccess) {
      resetForm();
      reqList({ data: candidateNotePgBtn, id: candidateID });
    }
  }, [resCreate.isSuccess, resUpdate.isSuccess]);

  const resetForm = () => {
    setNoteContent('');
    setEditMode(false);
    setNoteId(null);
    setShowInput(false);
  };

  const handleSubmitNote = () => {
    if (noteContent && candidateID) {
      reqCreate({ data: { notes: noteContent }, id: candidateID });
    }
  };

  const handleUpdateNote = () => {
    if (noteContent && noteId) {
      reqUpdate({ data: { notes: noteContent }, id: noteId });
    }
  };

  const handleRemove = () => {
    reqDel({ id: noteId });
  };

  const handleEditNote = (note: any) => {
    setNoteContent(note.notes || '');
    setEditMode(true);
    setNoteId(note.id || null);
    setShowInput(true);
  };

  const renderNotes = () => {
    if (Object.values(candidateNotes).length === 0) {
      return (
        <Text fontSize="14px" fontWeight={700} color="#6B6C7B">
          No notes available
        </Text>
      );
    }

    return Object.values(candidateNotes).map((note: any) => (
      <Box key={note.id} borderBottom="1px solid #E6E9EE" py={4}>
        <Flex justifyContent="space-between" alignItems="center">
          <Flex
            gap="12px"
            alignItems="center"
            fontWeight={500}
            fontSize="md"
            color="#545454"
          >
            <Tooltip
              label={`${note?.user?.first_name} ${note?.user?.last_name}`}
            >
              <Image
                borderRadius="full"
                boxSize="50px"
                src={note?.user?.profile_picture || User}
                border="1px solid #E7E9ED"
              />
            </Tooltip>
            <Stack spacing="8px">
              <Box fontSize="14px" fontWeight={400} color="#2B2D42">
                {parse(nl2br(note.notes))}
              </Box>
              <Box
                fontSize="14px"
                fontWeight={600}
                color="#9B9B9B"
                onMouseEnter={() => setHoverNoteId(note.id)}
                onMouseLeave={() => setHoverNoteId(null)}
              >
                {hoverNoteId === note.id
                  ? moment.utc(note.created_at).format('MM/DD/YYYY hh:mm:ss A')
                  : moment.utc(note.created_at).format('MM/DD/YYYY')}
              </Box>
            </Stack>
          </Flex>
          <Menu>
            <MenuButton>
              <Button
                as="button"
                htmlType="menu"
                variant="ghost"
                iconName="ellipsis-h"
                iconSize="xl"
              />
            </MenuButton>
            <MenuList fontSize="sm" position="relative" left="38px">
              <MenuItem onClick={() => handleEditNote(note)}>
                <Flex alignItems="center">
                  <FAIcon iconName="pencil" />
                  <Box ml={12} cursor="pointer">
                    Edit
                  </Box>
                </Flex>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  onOpenCancelModal();
                  setNoteId(note.id);
                }}
                sx={{ color: '#CB2C2C' }}
              >
                <Flex alignItems="center">
                  <FAIcon iconName="trash" />
                  <Box ml={12} cursor="pointer">
                    Remove
                  </Box>
                </Flex>
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Box>
    ));
  };

  return (
    <Modal
      isOpen={isOpen}
      closeOnOverlayClick={false}
      onClose={onClose}
      size="3xl"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent fontFamily="NunitoSans Regular">
        <ModalHeader
          background="default.white.800"
          borderRadius="4px 4px 0"
          p="18px 24px"
          fontSize="21px"
        >
          Notes
        </ModalHeader>
        <ModalCloseButton
          top="13px"
          right="20px"
          color="default.secondarytext"
        />
        <ModalBody
          borderRadius="0 0 4px 4px"
          fontFamily="NunitoSans Regular"
          sx={{
            position: 'relative',
            overflowY: 'scroll',
            minHeight: '450px',
            p: '0 24px',
          }}
        >
          <Box>
            <Flex
              justifyContent="flex-end"
              sx={{
                button: {
                  p: 0,
                  fontSize: '14px',
                  fontWeight: 600,
                  color: '#6930CA',
                },
              }}
            >
              {!showInput && (
                <Button
                  leftIcon="plus"
                  variant="ghost"
                  onClick={() => setShowInput(true)}
                >
                  Add
                </Button>
              )}
            </Flex>
            <Box>{renderNotes()}</Box>

            {showInput && (
              <Box
                position="sticky"
                right={0}
                bottom={0}
                width="100%"
                background="default.white.100"
                p="16px 16px 0"
              >
                <Stack
                  border="2px solid #6930CA"
                  borderRadius="10px 10px 0px 0px"
                  p="16px"
                >
                  <Textarea
                    value={noteContent}
                    placeholder="Type your note here..."
                    onChange={(e) => setNoteContent(e.target.value)}
                    minH="150px"
                    borderColor="#E2E8F0"
                  />
                  <Flex justifyContent="flex-end" gap={2}>
                    <Button onClick={resetForm}>Cancel</Button>
                    <Button
                      variant="solid"
                      onClick={editMode ? handleUpdateNote : handleSubmitNote}
                      isLoading={
                        editMode ? resUpdate.isLoading : resCreate.isLoading
                      }
                    >
                      {editMode ? 'Update Note' : 'Add Note'}
                    </Button>
                  </Flex>
                </Stack>
              </Box>
            )}

            {isOpenCancelModal && (
              <CancelModal
                isOpen={isOpenCancelModal}
                onClose={onCloseCancelModal}
                handleSubmit={handleRemove}
                label="remove a note"
                loading={resDel.isLoading}
              />
            )}
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
