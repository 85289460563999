import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';
import parse from 'html-react-parser';

import {
  Box,
  Flex,
  Grid,
  GridItem,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  Textarea,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import 'react-quill/dist/quill.snow.css';

import {
  useCreateCandidateNoteMutation,
  useDelCandidateNoteMutation,
  useListCandidateNoteMutation,
  useUpdateCandidateNoteMutation,
} from 'store/candidatenote.slice';

import { swalContent } from 'types';
import { AtsConfirm } from 'utils/swal';
import delay from 'utils/delay';
import Button from 'Library/Button';
import CancelModal from '../Drawer/Interviews/modal/Cancel';

import User from 'assets/images/user.png';
import FAIcon from 'components/lib/FAIcon';
import nl2br from 'utils/nl2br';

const CandidateNotes = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const { candidateNotes, candidateNotePgBtn } = useSelector(
    (state: any) => state.candidateNotes
  );
  const { candidateData } = useSelector((state: any) => state.candidates);

  const [reqCreate, resCreate] = useCreateCandidateNoteMutation();
  const [reqList] = useListCandidateNoteMutation();
  const [reqDel, resDel] = useDelCandidateNoteMutation();
  const [reqUpdate, resUpdate] = useUpdateCandidateNoteMutation();

  const [emailBodyKey, setEmailBodyKey] = useState(0);
  const [quilValue, setquilValue] = useState('');
  const [hover, setHover] = useState({ id: null, hover: null });
  const [loadingNoteId, setLoadingNoteId] = useState(null); // Track the note ID being currently deleted
  const [hasEditNote, SetHasEditNote] = useState(false);
  const [noteId, setNoteId] = useState(null);
  const [hasInput, setHasInput] = useState<boolean>(false);

  const {
    isOpen: isOpenCancelModal,
    onOpen: onOpenCancelModal,
    onClose: onCloseCancelModal,
  } = useDisclosure();

  const candidateID =
    candidateData?.id || params.candidatesId || params.candidateId;

  useEffect(() => {
    const load = async () => {
      setLoadingNoteId(null);
      await delay(500);
      await reqList({ data: candidateNotePgBtn, id: candidateID });
      await onCloseCancelModal();
    };
    load();
  }, [candidateID, resDel.isSuccess]);

  useEffect(() => {
    if (resCreate.isSuccess || resUpdate.isSuccess) {
      setquilValue('');
      reqList({ data: candidateNotePgBtn, id: candidateID });
      setHasInput(false);
    }
    if (resUpdate.isSuccess) {
      SetHasEditNote(false);
      setNoteId(null);
    }
  }, [resCreate.isSuccess, resUpdate.isSuccess]);

  const submitNote = () => {
    if (quilValue !== '') {
      setquilValue('');
      reqCreate({ data: { notes: quilValue }, id: candidateID });
      setEmailBodyKey((prevKey) => prevKey + 1);
    }
  };

  const content: swalContent = {
    title: 'Are you sure?',
    text: 'You are about to delete this notes.',
    buttons: ['Cancel', true],
    icon: 'warning',
  };

  const handleRemove = async () => {
    setLoadingNoteId(noteId); // Set the noteIdID of the note being deleted
    reqDel({ id: noteId });
  };

  const editNote = async (data: any) => {
    setquilValue(data?.notes || '');
    SetHasEditNote(true);
    setNoteId(data?.id || null);
    setHasInput(true);
  };

  const cancelUpdate = () => {
    setquilValue('');
    SetHasEditNote(false);
    setNoteId(null);
    setHasInput(false);
  };
  const UpdateNotes = async () => {
    if (quilValue !== '') {
      reqUpdate({ data: { notes: quilValue }, id: noteId });
    }
  };

  return (
    <Box mt="24px">
      <Flex
        justifyContent="space-between"
        sx={{
          button: { p: 0, fontSize: '14px', fontWeight: 600, color: '#6930CA' },
        }}
      >
        <Text
          sx={{
            height: 'fit-content',
            fontSize: '16px',
            fontWeight: 700,
            color: '#2B2D42',
          }}
          mb="26px"
        >
          Notes
        </Text>
        {!hasInput && (
          <Button
            leftIcon="plus"
            variant="ghost"
            onClick={() => setHasInput(true)}
          >
            Add
          </Button>
        )}
      </Flex>

      <Box mb="200px">
        {Object.values(candidateNotes).length > 0 ? (
          Object.values(candidateNotes).map((notes: any) => {
            return (
              <Box borderBottom="1px solid #E6E9EE" py={4}>
                <Grid
                  templateColumns="repeat(5, 1fr)"
                  gap={4}
                  key={`candidate-note-${notes.id}`}
                >
                  <GridItem colSpan={4}>
                    <Flex
                      gap="12px"
                      alignItems="center"
                      justifyContent="flex-start"
                      fontWeight={500}
                      fontSize="md"
                      color="#545454"
                    >
                      <Tooltip
                        label={[
                          notes?.user?.first_name,
                          notes?.user?.last_name,
                        ].join(' ')}
                      >
                        <Image
                          borderRadius="full"
                          boxSize="50px"
                          placeSelf="center"
                          src={notes?.user?.profile_picture || User}
                          sx={{ border: '1px solid #E7E9ED', mr: '5px' }}
                        />
                      </Tooltip>
                      <Stack spacing="8px">
                        <Box
                          sx={{
                            fontSize: '14px',
                            fontWeight: 400,
                            color: '#2B2D42',
                          }}
                        >
                          {parse(nl2br(notes.notes))}
                        </Box>
                        <Box
                          sx={{
                            fontSize: '14px',
                            fontWeight: 600,
                            color: '#9B9B9B',
                          }}
                          onMouseEnter={() => {
                            setHover({ id: notes.id, hover: true });
                          }}
                          onMouseLeave={() => {
                            setHover({ id: notes.id, hover: false });
                          }}
                        >
                          {hover.id === notes.id && hover.hover
                            ? moment
                                .utc(notes.created_at)
                                .format('MM/DD/YYYY hh:mm:ss A')
                            : moment.utc(notes.created_at).format('MM/DD/YYYY')}
                        </Box>
                      </Stack>
                    </Flex>
                  </GridItem>
                  <GridItem colEnd={8}>
                    <Menu>
                      <MenuButton>
                        <Button
                          htmlType="menu"
                          variant="ghost"
                          iconName="ellipsis-h"
                          iconSize="xl"
                        />
                      </MenuButton>
                      <MenuList
                        fontSize="sm"
                        sx={{
                          position: 'relative',
                          left: '38px',
                        }}
                      >
                        <MenuItem onClick={() => editNote(notes)}>
                          <Flex
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <FAIcon iconName="pencil" />
                            <Box ml={12} cursor="pointer">
                              Edit
                            </Box>
                          </Flex>
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            onOpenCancelModal();
                            setNoteId(Number(notes.id));
                          }}
                          sx={{ color: '#CB2C2C' }}
                        >
                          <Flex
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <FAIcon iconName="trash" />
                            <Box ml={12} cursor="pointer">
                              Remove
                            </Box>
                          </Flex>
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </GridItem>
                </Grid>
              </Box>
            );
          })
        ) : (
          <Text sx={{ fontSize: '14px', fontWeight: 700, color: '#6B6C7B' }}>
            No notes available
          </Text>
        )}
      </Box>
      {hasInput && (
        <Box
          position="fixed"
          right={3}
          bottom="0"
          width="37%"
          background="default.white.100"
          p="16px 30px 0"
        >
          <Stack
            sx={{
              border: '2px solid #6930CA',
              borderRadius: '10px 10px 0px 0px',
              p: '16px',
            }}
          >
            <Textarea
              value={quilValue}
              placeholder="Type what ever you need here..."
              onChange={(e) => setquilValue(e.target.value)}
              minH="150px"
              borderColor="#E2E8F0"
            />
            <Box pt="2px" textAlign={'right'}>
              <Flex gap={2} justifyContent="flex-end">
                <Button onClick={() => cancelUpdate()}>Cancel</Button>
                {hasEditNote ? (
                  <Button
                    variant="solid"
                    onClick={() => UpdateNotes()}
                    isLoading={resUpdate.isLoading}
                  >
                    Update Note
                  </Button>
                ) : (
                  <Button
                    variant="solid"
                    onClick={() => submitNote()}
                    isLoading={resCreate.isLoading}
                    loadingText="Submitting"
                  >
                    Add Note
                  </Button>
                )}
              </Flex>
            </Box>
          </Stack>
        </Box>
      )}

      {isOpenCancelModal && (
        <CancelModal
          isOpen={isOpenCancelModal}
          onClose={onCloseCancelModal}
          handleSubmit={handleRemove}
          label="remove a note"
          loading={resDel.isLoading}
        />
      )}
    </Box>
  );
};
export default CandidateNotes;
