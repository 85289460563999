// Form handling
import { Field, Formik } from 'formik';

// Chakra UI components
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button as ChakraButton,
  Box,
  Checkbox,
  CloseButton,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';

// React and related hooks
import { useMemo } from 'react';

// Third-party libraries
import Select from 'react-select';

// Custom components
import Button from 'Library/Button';
import ScrollToFieldError from 'components/app/ScrollError';
import AtsSelect from 'components/app/AtsSelect';
import StreamAttachmentComponent from 'components/app/Candidates/stream';
import FAIcon from 'components/lib/FAIcon';
import EmailPreviewModal from 'components/app/Jobs/Modal/FollowUp/Layout/Preview';
import AddressComponent from 'components/app/Jobs/AddressComponent';
import NotesModal from 'components/app/Candidates/Modals/Notes';
import AtsSelectContact from 'components/app/AtsSelectContact';
import TemplateAddModal from 'components/app/Global/TemplateModal';
import AtsEmailBody from 'components/app/Global/Email/AIBody';

// Services
import { useAIGeneratorServices } from 'components/app/AIGenerator/useAIGeneratorServices';
import { useSubmitToClientServices } from './useSubmitToClientServices';

// Styles
import 'react-quill/dist/quill.snow.css';
import 'assets/css/minHquill.css';

interface SubmitToClientModalProps {
  isOpen: boolean;
  onClose: () => void;
  candidate: any;
  job_id?: number;
  callback: () => void;
  jobTitle?: string;
  clientName?: string;
  jobID?: string;
  clientID?: string;
}

export default function SubmitToClientModal({
  isOpen,
  onClose,
  candidate,
  job_id = null,
  callback,
  jobTitle,
  clientName,
  jobID,
  clientID,
}: SubmitToClientModalProps) {
  const {
    isOpen: isOpenAlert,
    onClose: onCloseAlert,
    onOpen: openAlert,
  } = useDisclosure({ defaultIsOpen: false });

  const {
    isOpen: isOpenPreview,
    onClose: onClosePreview,
    onOpen: openPreview,
  } = useDisclosure();

  const {
    isOpen: isOpenNotes,
    onClose: onCloseNotes,
    onOpen: onOpenNotes,
  } = useDisclosure();

  const {
    selectedVersion,
    setResumeUrl,
    jobData,
    errMsg,
    tokenLink,
    initialValues,
    onSubmit,
    validationSchema,
    tabIndex,
    pairPH,
    candidate_work_status,
    isLoadingEduc,
    candidateDegreeList,
    company,
    contactOption,
    onCcInputChange,
    setCcValue,
    onBccInputChange,
    setBccValue,
    previewOpen,
    setPreviewOpen,
    placholders,
    attachments,
    setAttachments,
    populateTemplate,
    submitTemplate,
    template,
    setTabIndex,
    isLoadingSubmit,
    uploading,
    ccOption,
    bccOption,
  } = useSubmitToClientServices({
    candidate,
    isOpen,
    clientID,
    job_id,
    jobID,
    openAlert,
    onClose,
    callback,
  });

  const FileRender = () => {
    return (
      <Box textAlign="center">
        {selectedVersion ? (
          <StreamAttachmentComponent
            filename={selectedVersion?.file_name}
            fileUrl={selectedVersion?.signedUrl}
            setResumeUrl={setResumeUrl}
            pdfHeight="80vh"
            docHeight="80vh"
          />
        ) : (
          <Text>No resume available</Text>
        )}
      </Box>
    );
  };

  const memoizedResume = useMemo(() => {
    return (
      <Box textAlign="center" overflow="auto" height="80vh" width="100%">
        {selectedVersion ? <FileRender /> : <Text>No resume available</Text>}
      </Box>
    );
  }, [selectedVersion]);

  const {
    isOpen: addOpen,
    onOpen: addOnOpen,
    onClose: addOnClose,
  } = useDisclosure();

  const {
    hasAIGenerate,
    setHasAIGenerate,
    setIsGenerate,
    handleValues,
    handleAIGenerator,
    setIsError,
    isMaximize,
  } = useAIGeneratorServices({ hasType: 'Submit' });

  return (
    <>
      <Modal
        isOpen={isOpen}
        closeOnOverlayClick={false}
        onClose={() => {
          onClose();
        }}
        size="full"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent fontFamily="NunitoSans Regular">
          <ModalHeader
            sx={{
              background: '#F9FAFB',
              borderRadius: '4px 4px 0',
              p: '21px 16px',
              textAlign: 'left',
            }}
          >
            <Flex gap="10px" alignItems="center">
              <Text
                sx={{
                  fontSize: '18px',
                  lineHeight: '18px',
                  fontWeight: 700,
                  color: '#2B2D42',
                }}
              >
                Submit To Client
              </Text>
              <Flex gap="5px" alignItems="center">
                <Link
                  href={`/jobs/${jobID || jobData.id}/details`}
                  target="_blank"
                  sx={{
                    fontSize: '16px',
                    fontWeight: 700,
                    color: 'primary.600',
                  }}
                  rel="noreferrer"
                >
                  {jobTitle || jobData.title}
                </Link>
                -
                <Link
                  href={`/clients/my-clients/${
                    clientID || jobData.client?.id
                  }/overview`}
                  target="_blank"
                  sx={{
                    fontSize: '16px',
                    fontWeight: 700,
                    color: 'primary.600',
                    mr: '24px',
                  }}
                  rel="noreferrer"
                >
                  {clientName || jobData.client?.name}
                </Link>
              </Flex>
            </Flex>
          </ModalHeader>
          {isOpenAlert && (
            <Box>
              <Alert status="error" justifyContent="space-around">
                <AlertIcon />
                <Box width="100%">
                  <AlertTitle>Oh snap!</AlertTitle>
                  <AlertDescription>{errMsg}</AlertDescription>
                  {tokenLink !== '' && (
                    <AlertDescription>
                      <Link href={tokenLink} isExternal>
                        Click to follow this link
                      </Link>
                    </AlertDescription>
                  )}
                </Box>
                <CloseButton
                  position="relative"
                  right={-1}
                  top={-3}
                  onClick={onCloseAlert}
                />
              </Alert>
            </Box>
          )}
          <ModalBody
            overflow="hidden"
            height="100vh"
            sx={{
              bgColor: '#F9FAFB',
              borderRadius: '0 0 4px 4px',
              p: 0,
            }}
          >
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              enableReinitialize={true}
              validationSchema={validationSchema}
            >
              {({
                values,
                handleChange,
                handleSubmit,
                setFieldValue,
                errors,
                touched,
              }) => (
                <form onSubmit={handleSubmit}>
                  <ScrollToFieldError />

                  <Flex
                    gap="10px"
                    sx={{
                      px: '16px',

                      '& #ai': {
                        overflowY: 'scroll',
                        height: '80vh',
                      },
                      '& ::-webkit-scrollbar': {
                        display: 'none',
                      },
                    }}
                  >
                    <Box
                      width="50%"
                      sx={{
                        display: hasAIGenerate ? 'none' : 'block',
                        height: '90vh',
                        bgColor: '#FFF',
                        borderRadius: '6px',
                        p: '16px',
                      }}
                    >
                      <Box mt="15px" textAlign="center">
                        {memoizedResume}
                      </Box>
                    </Box>

                    <Box
                      width={hasAIGenerate ? '65%' : '50%'}
                      sx={{
                        display: hasAIGenerate && isMaximize ? 'none' : 'flex',
                        overflowY: 'scroll',
                        height: '90vh',
                        m: hasAIGenerate && '8px 16px',
                        mr: hasAIGenerate && 0,
                      }}
                    >
                      {tabIndex === 1 ? (
                        <Stack spacing="10px" mb="100px" w="100%">
                          <Box
                            sx={{
                              bgColor: '#FFF',
                              borderRadius: '6px',
                              p: '16px',
                            }}
                          >
                            <Text mb="34px" fontWeight={700}>
                              Company Details
                            </Text>
                            <Flex gap="32px" mb="24px">
                              <FormControl
                                isInvalid={Boolean(
                                  !!errors.job_title && touched.job_title
                                )}
                              >
                                <FormLabel fontSize="14px" lineHeight="18px">
                                  Current Job Title
                                </FormLabel>
                                <Input
                                  id="job_title"
                                  name="job_title"
                                  type="text"
                                  variant="outline"
                                  value={values.job_title}
                                  onChange={handleChange}
                                  sx={{
                                    height: '38px',
                                    border: 'none',
                                    borderBottom: '1px solid',
                                    borderColor: '#E7E9ED',
                                    p: '8px 12px',
                                    fontSize: '14px',
                                  }}
                                />
                                <FormErrorMessage>
                                  {String(errors.job_title)}
                                </FormErrorMessage>
                              </FormControl>
                              <FormControl
                                isInvalid={Boolean(
                                  !!errors.company && touched.company
                                )}
                              >
                                <FormLabel fontSize="14px" lineHeight="18px">
                                  Current Company
                                </FormLabel>
                                <Input
                                  id="company"
                                  name="company"
                                  type="text"
                                  variant="outline"
                                  value={values.company}
                                  onChange={handleChange}
                                  sx={{
                                    height: '38px',
                                    border: 'none',
                                    borderBottom: '1px solid',
                                    borderColor: '#E7E9ED',
                                    p: '8px 12px',
                                    fontSize: '14px',
                                  }}
                                />
                                <FormErrorMessage>
                                  {String(errors.company)}
                                </FormErrorMessage>
                              </FormControl>
                            </Flex>
                            <Flex gap="32px" mb="32px">
                              <FormControl
                                isInvalid={Boolean(
                                  !!errors.work_status && touched.work_status
                                )}
                              >
                                <FormLabel fontSize="14px" lineHeight="18px">
                                  Work Status{' '}
                                  <Box as="span" color="caution.800">
                                    *
                                  </Box>
                                </FormLabel>
                                <Select
                                  id="work_status"
                                  name="work_status"
                                  placeholder="Select"
                                  onChange={(e: any) => {
                                    setFieldValue(
                                      'work_status',
                                      String(e.value)
                                    );
                                    pairPH('work_status', String(e.label));
                                  }}
                                  defaultValue={
                                    (candidate_work_status?.length > 0 &&
                                      candidate_work_status
                                        .map((item: any) => ({
                                          label: item.status,
                                          value: item.id,
                                        }))
                                        .filter(
                                          (val: any) =>
                                            val.value ===
                                            Number(values.work_status)
                                        )[0]) ||
                                    values.work_status
                                  }
                                  options={
                                    candidate_work_status?.length > 0 &&
                                    candidate_work_status.map((item: any) => ({
                                      label: item.status,
                                      value: item.id,
                                    }))
                                  }
                                  styles={{
                                    control: (styles: any) => ({
                                      ...styles,
                                      fontSize: '14px',
                                      border: 'none',
                                      borderBottom: '1px solid #E7E9ED',
                                      borderRadius: '4px',
                                      cursor: 'pointer',
                                      boxShadow: 'none',
                                      '&:hover': {},
                                    }),
                                    dropdownIndicator: (styles: any) => ({
                                      ...styles,
                                      color: '#676767',
                                    }),
                                    indicatorSeparator: () => ({
                                      display: 'none',
                                    }),
                                    placeholder: (styles: any) => ({
                                      ...styles,
                                      fontSize: '14px',
                                      color: '#9B9B9B',
                                    }),
                                    multiValue: (styles: any) => ({
                                      ...styles,
                                      background: '#F7FAFC',
                                      border: '1px solid #E2E8F0',
                                      borderRadius: '6px',
                                      '&& div:first-of-type': {
                                        color: '#2B2D42',
                                      },
                                    }),
                                  }}
                                />
                                <FormErrorMessage>
                                  {String(errors.work_status)}
                                </FormErrorMessage>
                              </FormControl>
                              <FormControl
                                isInvalid={Boolean(
                                  !!errors.linked_in_url &&
                                    touched.linked_in_url
                                )}
                              >
                                <FormLabel fontSize="14px" lineHeight="18px">
                                  LinkedIn
                                </FormLabel>
                                <Input
                                  id="linked_in_url"
                                  name="linked_in_url"
                                  type="text"
                                  variant="outline"
                                  value={values.linked_in_url}
                                  onChange={(e: any) => {
                                    handleChange(e);
                                    pairPH(
                                      'linked_in_url',
                                      String(e.target.value)
                                    );
                                  }}
                                  sx={{
                                    height: '38px',
                                    border: 'none',
                                    borderBottom: '1px solid',
                                    borderColor: '#E7E9ED',
                                    p: '8px 12px',
                                    fontSize: '14px',
                                  }}
                                />
                                <FormErrorMessage>
                                  {String(errors.linked_in_url)}
                                </FormErrorMessage>
                              </FormControl>
                            </Flex>
                            <Flex gap="32px" mb="32px">
                              <FormControl
                                isInvalid={Boolean(
                                  !!errors.education && touched.education
                                )}
                              >
                                <FormLabel fontSize="14px" lineHeight="18px">
                                  Degree
                                </FormLabel>
                                <AtsSelect
                                  id="education"
                                  name="education"
                                  placeholder="Select"
                                  variant="outline"
                                  onChange={(e: any) => {
                                    const educData = {
                                      label: e.label,
                                      value: values.education?.value || -1,
                                    };
                                    setFieldValue('education', educData);
                                    pairPH('education', String(e.label));
                                  }}
                                  isMulti={false}
                                  isLoading={isLoadingEduc}
                                  defaultValue={values.education}
                                  options={candidateDegreeList}
                                  styles={{
                                    control: (styles: any) => ({
                                      ...styles,
                                      fontSize: '14px',
                                      border: 'none',
                                      borderBottom: '1px solid #E7E9ED',
                                      borderRadius: '4px',
                                      cursor: 'pointer',
                                      boxShadow: 'none',
                                      '&:hover': {},
                                    }),
                                    dropdownIndicator: (styles: any) => ({
                                      ...styles,
                                      color: '#676767',
                                    }),
                                    indicatorSeparator: () => ({
                                      display: 'none',
                                    }),
                                    placeholder: (styles: any) => ({
                                      ...styles,
                                      fontSize: '14px',
                                      color: '#9B9B9B',
                                    }),
                                    multiValue: (styles: any) => ({
                                      ...styles,
                                      background: '#F7FAFC',
                                      border: '1px solid #E2E8F0',
                                      borderRadius: '6px',
                                      '&& div:first-of-type': {
                                        color: '#2B2D42',
                                      },
                                    }),
                                  }}
                                />

                                <FormErrorMessage>
                                  {String(errors.education)}
                                </FormErrorMessage>
                              </FormControl>
                              <FormControl></FormControl>
                            </Flex>
                          </Box>

                          <Box
                            sx={{
                              bgColor: '#FFF',
                              borderRadius: '6px',
                              p: '16px',
                            }}
                          >
                            <Text mb="34px" fontWeight={700}>
                              Address Information
                            </Text>
                            <AddressComponent
                              setFieldValue={setFieldValue}
                              handleChange={handleChange}
                              cityField={{
                                name: 'city',
                                value: values.city,
                                errors: errors.city,
                                touched: touched.city,
                              }}
                              stateField={{
                                name: 'state_province',
                                value: values.state_province,
                                errors: errors.state_province,
                                touched: touched.state_province,
                              }}
                              zipField={{
                                name: 'zip_code',
                                value: values.zip_code,
                                errors: errors.zip_code,
                                touched: touched.zip_code,
                              }}
                            />
                          </Box>
                        </Stack>
                      ) : (
                        <Stack spacing="10px" w="100%">
                          <Stack
                            spacing="16px"
                            sx={{
                              bgColor: '#FFF',
                              borderRadius: '6px',
                              p: '16px',
                            }}
                          >
                            <Flex gap="16px">
                              <FormControl
                                isInvalid={Boolean(!!errors.to && touched.to)}
                              >
                                <FormLabel fontSize="14px" lineHeight="18px">
                                  To{' '}
                                  <Box as="span" color="caution.800">
                                    *
                                  </Box>
                                </FormLabel>
                                <Field
                                  id="to"
                                  name="to"
                                  component={AtsSelectContact}
                                  value={values.to}
                                  client={company}
                                  options={contactOption}
                                  placeholder="Select A Contact"
                                  defaultValue={values.to}
                                  isSaveInput={true}
                                  styles={{
                                    control: (styles: any) => ({
                                      ...styles,
                                      fontSize: '14px',
                                      border: 'none',
                                      borderBottom: '1px solid #E7E9ED',
                                      borderRadius: '4px',
                                      cursor: 'pointer',
                                      boxShadow: 'none',
                                      '&:hover': {},
                                    }),
                                    valueContainer: (styles: any) => ({
                                      ...styles,
                                      maxHeight: '33px',
                                      overflowY: 'scroll',
                                    }),
                                    dropdownIndicator: (styles: any) => ({
                                      ...styles,
                                      color: '#676767',
                                    }),
                                    indicatorSeparator: () => ({
                                      display: 'none',
                                    }),
                                    placeholder: (styles: any) => ({
                                      ...styles,
                                      fontSize: '14px',
                                      color: '#9B9B9B',
                                    }),
                                    multiValue: (styles: any) => ({
                                      ...styles,
                                      background: '#F7FAFC',
                                      border: '1px solid #E2E8F0',
                                      borderRadius: '6px',
                                      '&& div:first-of-type': {
                                        color: '#2B2D42',
                                      },
                                    }),
                                  }}
                                />
                                <FormErrorMessage>
                                  {String(errors.to)}
                                </FormErrorMessage>
                              </FormControl>

                              <FormControl
                                isInvalid={Boolean(!!errors.cc && touched.cc)}
                              >
                                <FormLabel fontSize="14px" lineHeight="18px">
                                  Cc
                                </FormLabel>
                                <Field
                                  id="cc"
                                  name="cc"
                                  component={AtsSelectContact}
                                  value={values.cc}
                                  client={company}
                                  options={ccOption}
                                  isMulti={true}
                                  placeholder="cc"
                                  defaultValue={values.cc}
                                  onInputChange={onCcInputChange}
                                  onChange={(event: any) => setCcValue(event)}
                                  isSaveInput={true}
                                  styles={{
                                    control: (styles: any) => ({
                                      ...styles,
                                      fontSize: '14px',
                                      border: 'none',
                                      borderBottom: '1px solid #E7E9ED',
                                      borderRadius: '4px',
                                      cursor: 'pointer',
                                      boxShadow: 'none',
                                      '&:hover': {},
                                    }),
                                    valueContainer: (styles: any) => ({
                                      ...styles,
                                      maxHeight: '33px',
                                      overflowY: 'scroll',
                                    }),
                                    dropdownIndicator: (styles: any) => ({
                                      ...styles,
                                      color: '#676767',
                                    }),
                                    indicatorSeparator: () => ({
                                      display: 'none',
                                    }),
                                    placeholder: (styles: any) => ({
                                      ...styles,
                                      fontSize: '14px',
                                      color: '#9B9B9B',
                                    }),
                                    multiValue: (styles: any) => ({
                                      ...styles,
                                      background: '#F7FAFC',
                                      border: '1px solid #E2E8F0',
                                      borderRadius: '6px',
                                      '&& div:first-of-type': {
                                        color: '#2B2D42',
                                      },
                                    }),
                                  }}
                                />
                                <FormErrorMessage>
                                  {String(errors.cc)}
                                </FormErrorMessage>
                              </FormControl>

                              <FormControl
                                isInvalid={Boolean(!!errors.bcc && touched.bcc)}
                              >
                                <FormLabel fontSize="14px" lineHeight="18px">
                                  Bcc
                                </FormLabel>
                                <Field
                                  id="bcc"
                                  name="bcc"
                                  component={AtsSelectContact}
                                  value={values.bcc}
                                  client={company}
                                  options={bccOption}
                                  isMulti={true}
                                  placeholder="bcc"
                                  defaultValue={values.bcc}
                                  onInputChange={onBccInputChange}
                                  onChange={(event: any) => setBccValue(event)}
                                  isSaveInput={true}
                                  styles={{
                                    control: (styles: any) => ({
                                      ...styles,
                                      fontSize: '14px',
                                      border: 'none',
                                      borderBottom: '1px solid #E7E9ED',
                                      borderRadius: '4px',
                                      cursor: 'pointer',
                                      boxShadow: 'none',
                                      '&:hover': {},
                                    }),
                                    valueContainer: (styles: any) => ({
                                      ...styles,
                                      maxHeight: '33px',
                                      overflowY: 'scroll',
                                    }),
                                    dropdownIndicator: (styles: any) => ({
                                      ...styles,
                                      color: '#676767',
                                    }),
                                    indicatorSeparator: () => ({
                                      display: 'none',
                                    }),
                                    placeholder: (styles: any) => ({
                                      ...styles,
                                      fontSize: '14px',
                                      color: '#9B9B9B',
                                    }),
                                    multiValue: (styles: any) => ({
                                      ...styles,
                                      background: '#F7FAFC',
                                      border: '1px solid #E2E8F0',
                                      borderRadius: '6px',
                                      '&& div:first-of-type': {
                                        color: '#2B2D42',
                                      },
                                    }),
                                  }}
                                />

                                <FormErrorMessage>
                                  {String(errors.bcc)}
                                </FormErrorMessage>
                              </FormControl>
                            </Flex>

                            <FormControl
                              isInvalid={Boolean(
                                !!errors.subject && touched.subject
                              )}
                            >
                              <Flex
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <FormLabel fontSize="14px" lineHeight="18px">
                                  Subject{' '}
                                  <Box as="span" color="caution.800">
                                    *
                                  </Box>
                                </FormLabel>
                                <ChakraButton
                                  leftIcon={
                                    <FAIcon
                                      iconName={
                                        !previewOpen ? 'eye' : 'eye-slash'
                                      }
                                    />
                                  }
                                  variant={'borderless'}
                                  onClick={() => {
                                    setPreviewOpen(!previewOpen);
                                  }}
                                >
                                  Preview
                                </ChakraButton>
                              </Flex>

                              <Input
                                name="subject"
                                type="text"
                                placeholder="Subject"
                                variant="outline"
                                value={
                                  !previewOpen
                                    ? values.subject
                                    : placholders(values.subject)
                                }
                                onChange={(e: any) => {
                                  setFieldValue('subject', e.target.value);
                                }}
                                sx={{
                                  height: '38px',
                                  border: 'none',
                                  borderBottom: '1px solid',
                                  borderColor: '#E7E9ED',
                                  p: '8px 12px',
                                  fontSize: '14px',
                                }}
                              />
                              <FormErrorMessage>
                                {String(errors.subject)}
                              </FormErrorMessage>
                            </FormControl>
                          </Stack>

                          <Stack
                            sx={{
                              position: 'relative',
                              bgColor: '#FFF',
                              borderRadius: '6px',
                              p: '16px',
                            }}
                          >
                            <FormControl
                              isInvalid={Boolean(!!errors.body && touched.body)}
                            >
                              <AtsEmailBody
                                value={
                                  !previewOpen
                                    ? values.body
                                    : placholders(values.body)
                                }
                                onChange={(e: any) => {
                                  setFieldValue('body', e);
                                }}
                                height={300}
                                hasAIGenerate={hasAIGenerate}
                                setHasAIGenerate={setHasAIGenerate}
                                setIsGenerate={setIsGenerate}
                                handleValues={handleValues}
                                setIsError={setIsError}
                                subject={values.subject}
                                body={values.body}
                                attachments={attachments}
                                setAttachments={setAttachments}
                              />
                              <FormErrorMessage>
                                {String(errors.body)}
                              </FormErrorMessage>
                            </FormControl>
                          </Stack>

                          <FormControl
                            sx={{
                              position: 'absolute',
                              top: '10px',
                              right: '16px',
                              height: '40px',
                              width: '169px',
                            }}
                          >
                            <Select
                              placeholder="Select a Template"
                              isMulti={false}
                              defaultValue={populateTemplate(submitTemplate)}
                              options={template}
                              onChange={(e: any) => {
                                setFieldValue('body', e.body);
                                setFieldValue('subject', e.subject);
                                setAttachments(e.attachments);
                              }}
                              styles={{
                                control: (styles: any) => ({
                                  ...styles,
                                  fontSize: '14px',
                                  border: 'none',
                                  borderRadius: '4px',
                                  cursor: 'pointer',
                                  boxShadow: '0px 2px 8px 0px #0000000A',
                                }),
                                dropdownIndicator: (styles: any) => ({
                                  ...styles,
                                  color: '#718096',
                                }),
                                indicatorSeparator: () => ({
                                  display: 'none',
                                }),
                                placeholder: (styles: any) => ({
                                  ...styles,
                                  fontSize: '14px',
                                  color: '#2B2D42',
                                }),
                                multiValue: (styles: any) => ({
                                  ...styles,
                                  background: '#F7FAFC',
                                  border: '1px solid #E2E8F0',
                                  borderRadius: '6px',
                                  '&& div:first-of-type': {
                                    color: '#6930CA',
                                    fontWeight: 600,
                                  },
                                }),
                              }}
                              components={{
                                Option: ({ label, data, innerProps }) => {
                                  const isCreate =
                                    label === 'Create New Template';
                                  return (
                                    <div {...innerProps}>
                                      {isCreate && <Divider m={0} />}
                                      <Text
                                        sx={{
                                          p: '8px 16px',
                                          fontSize: '12px',
                                          color: '#2B2D42',
                                          cursor: 'pointer',
                                          fontWeight: isCreate ? 700 : 400,
                                        }}
                                        onClick={(e) => {
                                          isCreate
                                            ? addOnOpen()
                                            : innerProps.onClick(e);
                                        }}
                                      >
                                        {label}
                                      </Text>
                                    </div>
                                  );
                                },
                              }}
                            />
                          </FormControl>

                          <Stack
                            sx={{
                              position: 'relative',
                              bgColor: '#FFF',
                              borderRadius: '6px',
                              p: '16px',
                              mb: '100px',
                            }}
                          >
                            <Checkbox
                              colorScheme="purple"
                              color="default.secondarytext"
                              defaultChecked={values.primary}
                              name="primary"
                              id="primary"
                              onChange={handleChange}
                            >
                              Attach Primary Resume
                            </Checkbox>
                          </Stack>
                        </Stack>
                      )}
                    </Box>

                    {hasAIGenerate && <>{handleAIGenerator(setFieldValue)}</>}
                  </Flex>

                  <ModalFooter
                    position="sticky"
                    bottom="0"
                    background="default.white.100"
                    boxShadow="0px -3px 7px rgba(0, 0, 0, 0.05)"
                    zIndex={2}
                  >
                    <Flex width="100%" justifyContent="space-between">
                      <Box>
                        <Button
                          variant="outline"
                          onClick={onOpenNotes}
                          left="0px"
                          color="primary.800"
                        >
                          Notes
                        </Button>
                      </Box>
                      {tabIndex === 2 && (
                        <Flex gap="10px">
                          <Button variant="borderless" onClick={onClose}>
                            Cancel
                          </Button>
                          <Button onClick={() => setTabIndex(1)}>
                            Previous
                          </Button>
                          <Menu closeOnSelect={true}>
                            <Flex>
                              <Box
                                sx={{
                                  button: {
                                    p: '8px 4px',
                                    pl: '8px',
                                    height: '40px',
                                    fontSize: '12px',
                                    borderRight: 'none',
                                    borderRadius: '6px 0 0 6px',
                                  },
                                }}
                              >
                                <ChakraButton type="button" onClick={() => {}}>
                                  Save As
                                </ChakraButton>
                              </Box>
                              <MenuButton
                                as={ChakraButton}
                                type="button"
                                sx={{
                                  p: '8px',
                                  height: '40px',
                                  fontSize: '12px',
                                  borderLeft: 'none',
                                  borderRadius: '0 6px 6px 0',
                                }}
                              >
                                |{' '}
                                <Box as="span" ml="10px">
                                  <FAIcon iconName="chevron-down" />
                                </Box>
                              </MenuButton>
                            </Flex>
                            {
                              <MenuList
                                fontSize="sm"
                                maxHeight="120px"
                                overflowY="auto"
                              >
                                <MenuItem onClick={() => {}}>
                                  Save as a template
                                </MenuItem>
                                <MenuItem onClick={() => {}}>
                                  Save as a template on Job
                                </MenuItem>
                              </MenuList>
                            }
                          </Menu>
                          <Button
                            variant="solid"
                            type="submit"
                            disabled={
                              uploading || isOpenAlert || isLoadingSubmit
                            }
                            onClick={handleSubmit}
                            loading={isLoadingSubmit}
                          >
                            Send
                          </Button>
                        </Flex>
                      )}

                      {tabIndex === 1 && (
                        <Flex gap="10px">
                          <Button variant="borderless" onClick={onClose}>
                            Cancel
                          </Button>
                          <Button
                            variant="solid"
                            onClick={() => setTabIndex(2)}
                          >
                            Next
                          </Button>
                        </Flex>
                      )}
                    </Flex>

                    {isOpenPreview && (
                      <EmailPreviewModal
                        data={{
                          ...values,
                          attachments,
                          resume: `${selectedVersion?.original_file_name}.${
                            selectedVersion?.file_name.split('.').splice(-1)[0]
                          }`,
                        }}
                        isOpen={isOpenPreview}
                        onClose={onClosePreview}
                        onSubmit={handleSubmit}
                        disabled={uploading || isOpenAlert}
                        isLoading={isLoadingSubmit}
                      />
                    )}

                    {isOpenNotes && (
                      <NotesModal isOpen={isOpenNotes} onClose={onCloseNotes} />
                    )}

                    {addOpen && (
                      <TemplateAddModal isOpen={addOpen} onClose={addOnClose} />
                    )}
                  </ModalFooter>
                </form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
