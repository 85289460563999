import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

import moment from 'moment';

import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Icon,
  Link,
  useDisclosure,
} from '@chakra-ui/react';
import { BsEnvelope, BsLinkedin, BsTelephone } from 'react-icons/bs';

import LeadStatus from 'components/app/LeadStatus';
import Button from 'Library/Button';

import ClientsContactsTabs from './Tabs';

import { ContactsData } from 'types';
import toUrl from 'utils/toUrl';
import { getContact } from 'store/contact.slice';
import ContactEmail from './Modal/Email';
import ClientsEditContactModal from './Modal/edit';
import { useGetAsContactMutation } from 'store/leads.slice';
import { navClient } from 'store/client.slice';

interface ClientContactsProps {
  isOpen: boolean;
  rowId: number;
  onClose: () => void;
  totalClients: number;
  handlePrevData: (client: ContactsData) => void;
  handleNextData: (client: ContactsData) => void;
}

export default function ClientsContactsDrawer({
  isOpen,
  onClose,
  rowId,
  totalClients,
  handlePrevData,
  handleNextData,
}: ClientContactsProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const { contactList, contactData } = useSelector(
    (state: any) => state.contacts
  );
  const { clientTab } = useSelector((state: any) => state.clients);
  const clientID = params.clientsId;
  const [reqContacts, resContact] = useGetAsContactMutation();

  useEffect(() => {
    console.log({ params, contactData });
    reqContacts({ id: clientID });
  }, []);

  useEffect(() => {
    if (resContact.isSuccess) {
      const data = resContact.data as any;
      let lead = data.data;
      try {
        dispatch(getContact({ contactData: lead }));
      } catch (e) {
        console.warn({ e });
        console.log({ lead, contactData });
      }
    }
  }, [resContact.isSuccess]);

  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();

  useEffect(() => {
    if (clientID) {
      const getData =
        contactList &&
        contactList.filter((client: any) => client.id === Number(clientID))[0];
      dispatch(getContact({ contactData: getData }));
    }
  }, [contactList, clientID, dispatch]);

  const handleOpenDrawer = () => {
    if (clientID) {
      return true;
    }
    return isOpen;
  };

  const {
    isOpen: isOpenEmail,
    onOpen: onOpenEmail,
    onClose: onCloseEmail,
  } = useDisclosure();

  const drawerClose = () => {
    dispatch(navClient({ clientNav: clientTab }));
    onClose();
    dispatch(getContact({ contactData: {} }));
    navigate('/clients/contacts');
  };

  return (
    <>
      {contactData && (
        <Drawer
          onClose={() => drawerClose()}
          isOpen={handleOpenDrawer()}
          size="xl"
        >
          <DrawerOverlay top="60px" />
          <DrawerContent
            maxW="80vw"
            top="62px! important"
            fontFamily="NunitoSans Regular"
          >
            <DrawerCloseButton
              position="absolute"
              top="0px"
              left="-40px"
              borderRadius="unset"
              p="20px"
              bgColor="#E6E9EE"
              color="#6B6C7B"
              _hover={{
                backgroundColor: 'none',
              }}
            />
            <DrawerHeader
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p="0 0 0 32px"
              borderBottom="1px solid #E6E9EE"
              fontSize="sm"
              fontWeight="500"
              color="#6B6C7B"
            >
              <Box>
                Showing <b>{rowId}</b> of {totalClients}
              </Box>
              <Flex>
                <Box borderLeft="1px solid #6B6C7B" borderRadius="unset">
                  <Button
                    iconName="chevron-left"
                    variant="ghost"
                    onClick={() => rowId !== 1 && handlePrevData(contactData)}
                    disabled={rowId === 1}
                  />
                </Box>

                <Box borderLeft="1px solid #6B6C7B" borderRadius="unset">
                  <Button
                    iconName="chevron-right"
                    variant="ghost"
                    onClick={() =>
                      rowId !== totalClients && handleNextData(contactData)
                    }
                    disabled={rowId === totalClients}
                  />
                </Box>
              </Flex>
            </DrawerHeader>
            <DrawerBody p="0">
              <Box
                bg="default.white.800"
                borderBottom="1px solid"
                borderColor="default.white.400"
                p="32px"
                pb="60px"
              >
                <Flex justifyContent="space-between" mb="10px">
                  <Flex gap={3} alignItems="center">
                    <Box
                      fontSize="20px"
                      fontWeight="600"
                      color="default.primarytext"
                    >
                      {contactData?.first_name + ' ' + contactData?.last_name}
                    </Box>
                    <Box>
                      <LeadStatus
                        status={contactData?.leadStatus?.lead_status}
                      />
                    </Box>
                  </Flex>
                  <Flex gap="30px" alignItems="center">
                    <Link
                      href={toUrl(contactData?.linked_in_url)}
                      target="_blank"
                      style={{ display: 'flex', gap: '7px' }}
                      rel="noreferrer"
                      fontSize="20px"
                    >
                      <Icon as={BsLinkedin} />
                    </Link>
                    <Link fontSize="20px" onClick={() => onOpenEmail()}>
                      <Icon as={BsEnvelope} />
                    </Link>
                    <Link
                      fontSize="20px"
                      href={`tel:${contactData?.personal_phone}`}
                    >
                      <Icon as={BsTelephone} />
                    </Link>
                    <Button
                      variant="solid"
                      leftIcon="pencil"
                      onClick={onEditOpen}
                    >
                      Edit
                    </Button>
                  </Flex>
                </Flex>
                <Flex justifyContent="space-between">
                  <Box>
                    <Flex
                      gap={2}
                      fontSize="md"
                      color="default.gray.600"
                      mb="10px"
                    >
                      <Box fontSize="md" color="default.gray.800">
                        {contactData?.title}
                      </Box>
                      |
                      <Box fontSize="md" color="default.gray.800">
                        <Link
                          href={`/clients/my-clients/${contactData?.client_id}/overview`}
                          target="_blank"
                        >
                          {contactData?.company}
                        </Link>
                      </Box>
                    </Flex>
                    <Flex
                      justifyContent="space-between"
                      alignItems="center"
                      fontSize="md"
                      color="default.gray.600"
                    >
                      <Flex gap={2}>
                        <Box fontSize="sm" color="#6B6C7B">
                          Added On:{' '}
                          {moment
                            .utc(contactData?.created_at)
                            .format('MM/DD/YYYY')}
                        </Box>
                        |
                        <Box fontSize="sm" color="#6B6C7B">
                          Contact Owner:{' '}
                          {[
                            contactData?.leadOwner?.first_name,
                            contactData?.leadOwner?.last_name,
                          ].join(' ')}
                        </Box>
                      </Flex>
                    </Flex>
                  </Box>
                </Flex>
              </Box>
              <Box mt="-39px" p="0 32px">
                <ClientsContactsTabs />
              </Box>

              {isEditOpen && (
                <ClientsEditContactModal
                  isOpen={isEditOpen}
                  onClose={onEditClose}
                />
              )}
            </DrawerBody>
          </DrawerContent>
          {isOpenEmail && (
            <ContactEmail
              isOpen={isOpenEmail}
              onClose={onCloseEmail}
              id={contactData.id}
              email={contactData?.primary_email}
            />
          )}
        </Drawer>
      )}
    </>
  );
}
