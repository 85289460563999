import {
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
} from '@chakra-ui/react';
import MyClientsTable from './MyClients/Table';
import AllClientsTable from './AllClients/Table';
import ContactsClientsTable from './Contacts/Table';
import { useDispatch, useSelector } from 'react-redux';
import { navClient, useViewClientMutation } from 'store/client.slice';
import { useNavigate, useParams } from 'react-router';
import { useEffect, useState } from 'react';

export default function ClientsPage() {
  const dispatch = useDispatch();
  const params = useParams();

  document.documentElement.dir = 'ltr';

  const naviget = useNavigate();

  const tabIndex: any = {
    'my-clients': 0,
    'all-clients': 1,
    contacts: 2,
  };

  const { clientNav } = useSelector((state: any) => state.clients);
  const [activeTab, setTab] = useState(params.clientsTab);

  const tabNav = params.clientsTab === 'all-clients' ? 'all-clients' : clientNav;

  const [reqClient] = useViewClientMutation();

  const {
    isOpen: isOpenMyClients,
    onOpen: onOpenMyClients,
    onClose: onCloseMyClients,
  } = useDisclosure();

  const tabChange = (tab: string) => {
    dispatch(navClient({ clientNav: tab }));
    dispatch(navClient({ clientTab: tab }));
    naviget(`/clients/${tab}`);
    setTab(tab);
  };

  useEffect(() => {
    const fetch = async () => {
      await reqClient({ id: params.clientsId });
    };
    
    if (
      clientNav === 'my-clients' &&
      params.clientsTab !== 'all-clients' &&
      params.clientsId
    ) {
      fetch();
      onOpenMyClients();
    }
  }, [clientNav, params.clientsId, isOpenMyClients]);

  console.log(params, 'xx');

  return (
    <Box>
      <Tabs colorScheme="purple" defaultIndex={tabIndex[params.clientsTab]}>
        <TabList fontSize="md" fontWeight="bold" mx={10}>
          <Tab
            _focus={{ boxShadow: 'none !important' }}
            onClick={() => tabChange('my-clients')}
          >
            My Clients
          </Tab>
          <Tab
            _focus={{ boxShadow: 'none !important' }}
            onClick={() => tabChange('all-clients')}
          >
            All Clients
          </Tab>
          <Tab
            _focus={{ boxShadow: 'none !important' }}
            onClick={() => tabChange('contacts')}
          >
            Contacts
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            {activeTab === 'my-clients' && (
              <MyClientsTable
                isOpen={isOpenMyClients}
                onOpen={onOpenMyClients}
                onClose={onCloseMyClients}
              />
            )}
          </TabPanel>
          <TabPanel>
            {activeTab === 'all-clients' && <AllClientsTable />}
          </TabPanel>
          <TabPanel>
            {activeTab === 'contacts' && <ContactsClientsTable />}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}
