import React from 'react';
import { useDispatch } from 'react-redux';
import { Routes, Route } from 'react-router';
import ConfettiExplosion from 'react-confetti-explosion';

import { Box, Image, Text } from '@chakra-ui/react';

import confetti from 'assets/images/confetti.svg';
import confetti1 from 'assets/images/confetti1.svg';

import '../../theme/fonts.css';

import Loader from 'Library/Loader';

import LoginAppNavBar from 'components/app/AppNavbar';
import ApprovalsPage from 'components/app/Approvals/Page';
import CandidatesPage from 'components/app/Candidates/Page';
import CandidatesResume from 'components/app/Candidates/Resume';
import ClientsPage from 'components/app/Clients/Page';
import Dashboard from 'components/app/DashBoard';
import FooterNotif from 'components/app/FooterNotif';
import MsGraph from 'components/app/MsGraph';
import MsGraphMe from 'components/app/MsGraph/me';
import MsGraphOuth from 'components/app/MsGraph/oauth';
import MyProfile from 'components/app/MyProfile';
import ParseQueue from 'components/app/ParseQueue';
import Settings from 'components/app/Settings';
import EmailSignatures from 'components/app/Settings/EmailSignatures';
import EmailTemplateTab from 'components/app/Settings/EmailTemplates';
import RingCentralAuth from 'components/app/Settings/RingCentral/auth';
import ScheduleInterview from 'components/app/Settings/ScheduleInterview';
import Users from 'components/app/Settings/Users';
import SwitchAccount from 'components/app/SwitchAccount';

import NotFoundPage from 'pages/404';

import { TalieDrawer } from 'components/app/TalieAI/Drawer';

import { Inboxes } from 'pages/Inboxes';
import { Jobs } from 'pages/Jobs';
import { Leads } from 'pages/Leads';
import { Engage } from 'pages/Engage';

import { updateApp } from 'store/app.slice';
import { useMainPageServices } from './useMainPageServices';

const MainPage = () => {
  const dispatch = useDispatch();
  const {
    config,
    isNavBar,
    handleOpenParseQueue,
    handleOpenFooterNotif,
    handleSwitchAccount,
    isOpenParseQueue,
    onCloseParseQueue,
    isDashboard,
    isEngage,
    isCongrats,
    first_name,
    setIsNavBar,
    isOpenFooterNotif,
    onCloseFooterNotif,
    handleNotification,
    hasSwitchedAccount,
    constants,
    callViewProfile,
    profile,
    // teamMembers,
    auth,
    isTalieShow,
    isBackDrop,
  } = useMainPageServices();
  return (
    <Box
      bg="default.white.800"
      m="0"
      className="mainpage-container"
      h="100vh"
      boxSize="border-box"
      border={hasSwitchedAccount !== null ? '3px solid' : 'none'}
      borderColor="primary.800"
    >
      {hasSwitchedAccount !== null && (
        <Box
          position="relative"
          w="100vw"
          zIndex="99999999"
          className="switch account container"
        >
          <SwitchAccount
            member={hasSwitchedAccount}
            handleSwitchAccount={handleSwitchAccount}
          />
        </Box>
      )}
      <Box pb={6} position="relative" w="100%">
        {isNavBar && (
          <LoginAppNavBar
            handleOpenParseQueue={handleOpenParseQueue}
            handleOpenFooterNotif={handleOpenFooterNotif}
            handleSwitchAccount={handleSwitchAccount}
            handleNotification={handleNotification}
            constants={constants}
            callViewProfile={callViewProfile}
            profile={profile}
            // teamMembers={teamMembers}
          />
        )}
      </Box>
      {isOpenParseQueue && (
        <ParseQueue isOpen={isOpenParseQueue} onClose={onCloseParseQueue} />
      )}

      {isBackDrop && (
        <Box
          sx={{
            zIndex: 998,
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bgColor: 'black',
            opacity: '30%',
            height: '100%',
          }}
        />
      )}

      <Box
        sx={{
          px: !isDashboard && !isEngage && 4,
          pt: !isDashboard && !isEngage ? 20 : 10,
        }}
        zIndex="1"
        onClick={() => dispatch(updateApp({ isCongrats: false }))}
      >
        {isCongrats && (
          <Box
            sx={{
              zIndex: 999999999999,
              position: 'absolute',
              top: '30vh',
              left: 0,
              right: 0,
              bgColor: '#FAF5FF',
              width: '80%',
              border: '0.5px solid #6930ca1f',
              borderRadius: '20px',
              margin: '0 auto',
              py: '20px',
              display: 'flex',
              flexDir: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '40px',
              fontWeight: 700,
              color: 'primary.800',
              boxShadow: '-7px 5px 8px 2px rgba(0, 0, 0, 0.05)',
            }}
          >
            <ConfettiExplosion {...config} />
            <Box
              sx={{
                img: {
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  height: '100%',
                },
              }}
            >
              <Image src={confetti} alt="logo" />
            </Box>
            <Box>Woohoooo!! Congrats, {first_name}!</Box>
            <Box> You’re Awesome!</Box>
            <Box
              sx={{
                img: {
                  position: 'absolute',
                  bottom: 0,
                  right: 0,
                  height: '100%',
                },
              }}
            >
              <Image src={confetti1} alt="logo" />
            </Box>
          </Box>
        )}

        <Box position="relative">{isTalieShow && <TalieDrawer />}</Box>

        <Box sx={{ filter: isCongrats && 'blur(2px)', height: '100%' }}>
          <React.Suspense fallback={<Loader />}>
            <Routes>
              <Route path={'/'} element={<Dashboard />} />

              <Route path={'/inbox'} element={<Inboxes />}>
                <Route path=":settingTab" element={<Inboxes />}>
                  <Route path=":settingTabId" element={<Inboxes />} />
                </Route>
              </Route>

              <Route path={'/dashboard'} element={<Dashboard />} />

              <Route path="/jobs" element={<Jobs />}>
                <Route path=":jobsId" element={<Jobs />}>
                  <Route path=":jobsTab" element={<Jobs />}>
                    <Route path=":jobsTabId" element={<Jobs />}>
                      <Route path=":jobsTabNav" element={<Jobs />}>
                        <Route path=":candidateId" element={<Jobs />}>
                          <Route path=":jobsDrawerTab" element={<Jobs />} />
                        </Route>
                      </Route>
                    </Route>
                  </Route>
                </Route>
              </Route>

              <Route path="/candidates" element={<CandidatesPage />}>
                <Route path=":candidatesTabState" element={<CandidatesPage />}>
                  <Route path=":candidatesPageNum" element={<CandidatesPage />}>
                    <Route path=":candidatesId" element={<CandidatesPage />}>
                      <Route path=":candidatesTab" element={<CandidatesPage />}>
                        <Route
                          path=":candidatesTabId"
                          element={<CandidatesPage />}
                        />
                      </Route>
                    </Route>
                  </Route>
                </Route>
              </Route>

              <Route path="/clients" element={<ClientsPage />}>
                <Route path=":clientsTab" element={<ClientsPage />}>
                  <Route path=":clientsId" element={<ClientsPage />}>
                    <Route path=":clientsSubTab" element={<ClientsPage />}>
                      <Route path=":clientsTabId" element={<ClientsPage />} />
                    </Route>
                  </Route>
                </Route>
              </Route>

              <Route path="/leads" element={<Leads />}>
                <Route path=":leadsId" element={<Leads />}>
                  <Route path=":leadsTab" element={<Leads />}>
                    <Route path=":leadsTabId" element={<Leads />} />
                  </Route>
                </Route>
              </Route>

              <Route path="/approvals" element={<ApprovalsPage />}>
                <Route path=":approvalsId" element={<ApprovalsPage />}>
                  <Route path=":approvalsTab" element={<ApprovalsPage />}>
                    <Route path=":approvalsTabId" element={<ApprovalsPage />} />
                  </Route>
                </Route>
              </Route>

              <Route path="/profile/:profileID" element={<MyProfile />}></Route>

              <Route path="/settings" element={<Settings />}></Route>
              <Route path="settings/users" element={<Users />}></Route>
              <Route
                path="/settings/ringcentral/auth"
                element={<RingCentralAuth />}
              ></Route>
              <Route
                path="*"
                element={<NotFoundPage setIsNavBar={setIsNavBar} />}
              />
              <Route path="/msgraph" element={<MsGraph />}></Route>
              <Route path="/msgraph/oauth" element={<MsGraphOuth />}></Route>
              <Route path="/msgraph/me" element={<MsGraphMe />}></Route>
              <Route path="/email-template" element={<EmailTemplateTab />}>
                <Route
                  path=":templateTab"
                  element={<EmailTemplateTab />}
                ></Route>
              </Route>
              <Route path="/email-signature" element={<EmailSignatures />}>
                <Route
                  path=":signatureTab"
                  element={<EmailSignatures />}
                ></Route>
              </Route>
              <Route
                path="/schedule-interview"
                element={<ScheduleInterview />}
              ></Route>
              <Route
                path="/resume/:resumeId"
                element={<CandidatesResume />}
              ></Route>
              <Route path="/engage" element={<Engage />}>
                <Route path=":settingNav" element={<Engage />}>
                  <Route path=":settingTab" element={<Engage />}>
                    <Route path=":settingType" element={<Engage />}>
                      <Route path=":settingTypeId" element={<Engage />}>
                        <Route path=":settingSubType" element={<Engage />}>
                          <Route path=":settingSubId" element={<Engage />} />
                        </Route>
                      </Route>
                    </Route>
                  </Route>
                </Route>
              </Route>
            </Routes>
          </React.Suspense>
        </Box>
      </Box>
      {isOpenFooterNotif && (
        <FooterNotif isOpen={isOpenFooterNotif} onClose={onCloseFooterNotif} />
      )}
    </Box>
  );
};

export default MainPage;
