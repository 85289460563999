import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { getFieldErrorNames } from 'utils/getFieldErrorNames';

interface behaviorContent {
  behavior: any;
  block: any;
}

export default function ScrollToFieldError() {
  const { submitCount, isValid, errors } = useFormikContext();
  let scrollBehavior = { behavior: 'smooth' as any, block: 'center' as any };

  useEffect(() => {
    if (isValid) return;

    const fieldErrorNames = getFieldErrorNames(errors);
    if (fieldErrorNames.length <= 0) return;

    const element = document.querySelector('.chakra-form__error-message');
    if (!element) return;

    // Scroll to first known error into view
    element.scrollIntoView(scrollBehavior);

    // Formik doesn't (yet) provide a callback for a client-failed submission,
    // thus why this is implemented through a hook that listens to changes on
    // the submit count.
  }, [submitCount]); // eslint-disable-line react-hooks/exhaustive-deps

  return <></>;
}
